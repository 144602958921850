.hero {
    @apply relative w-full flex flex-col items-center justify-center gap-6;
    &-slider {
        @apply w-full flex h-[calc(70vh-148px)] sm:h-[calc(100vh-148px)] pb-10;
        .swiper {
            @apply w-full h-auto;
            .swiper-pagination-bullets {
                .swiper-pagination-bullet {
                    @apply rounded-none;
                    &-active {
                        @apply bg-white;
                    }
                } 
            }
        }
    }
    &-content {
        @apply text-center flex flex-col items-center justify-center;
        .title {
            @apply mb-0;
        }
    }
    &.no-image {
        .hero-content {
            @apply pb-10 pt-10 border-b-2 border-body;
        }
    }
}
.home {
    .hero {
        &-slider {
            @apply h-[calc(70vh-148px)] sm:h-[calc(100vh-148px)];
        }
    }
}